import { AxiosResponse } from "axios";

import api from "~/utils/api/api";

type InsurersResponse = {
  data: string[];
};

class InsuranceService {
  fetchInsurers = async (): Promise<AxiosResponse<InsurersResponse>> => {
    return api.get(`/insurance/insurers/`);
  };
}

const insuranceService = new InsuranceService();

export default insuranceService;
